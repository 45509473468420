<template>
  <div :class='`${$route.name}_main-ape secondary-nav`'>
    <el-menu v-if='showInnerMenu' :default-active='routePath' :background-color='`#fff`'
             :text-color='`#333333`'
             :active-text-color='`#ff3176`' class='el-submenu-header'
             mode='horizontal'
             @select='handleMenuSelect'>
      <el-menu-item v-for='(menu,key) in menus' :key='key' :index='menu.url'
                    :disabled='userPermissions.indexOf(menu.permission_name) === -1||menu.disabled'>
        <!--        <svg-icon :fill="'currentColor'" :class='`iconfont`' style='display: inline-block'-->
        <!--                  :icon-class="menu['icon']"></svg-icon>-->
        <span>{{ menu.display_name }}</span>
      </el-menu-item>
    </el-menu>
    <div :class="`main-content_bg ${$route.name}_main-content`">
      <transition name='el-fade-in-linear'>
        <keep-alive v-if="$route.meta&&$route.meta.alive!==undefined&&$route.meta.alive">
          <router-view></router-view>
        </keep-alive>
        <router-view v-else></router-view>
      </transition>
    </div>

  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'ApeMenu',
  computed: {
    ...mapGetters(['routePath', 'userPermissions', 'routePath']),
    showInnerMenu() {
      return this.$route.meta.hideInnerMenu === undefined ? true : !(this.$route.meta.hideInnerMenu)
    }
  },
  props: {
    menus: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {}
  },
  methods: {
    handleMenuSelect(routePath) {
      if (routePath)
        this.$router.push(routePath)
    }
  }
}
</script>

<style lang="scss" scoped>
.el-menu-item {
  font-size: 14px !important;
  height: 40px !important;
  line-height: 40px !important;
  padding: 0 10px;
  border-bottom: none;
  margin: 0 5px;

  /*margin-bottom: 1px !important;*/
}

/*.el-menu-item li {*/
/*  border: #fe346e 1px dashed !important;*/
/*}*/
::v-deep .el-menu-item + .is-active, .el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: none !important;
  background: linear-gradient(150deg, #FFEDF3, #faf0ff, #FFEDF3) !important;
  border-radius: 2px;
}

::v-deep .el-menu.el-menu--horizontal {
  //padding: 4px 0;
  width: 100%;
  box-shadow: 2px 0 black;
  margin: 0 0;
}

.main-content_bg {
  background-color: white;
  margin-top: 6px;
  padding: 8px 10px 0 10px;
  min-height: calc(100vh - 140px) !important;

}
</style>
